@keyframes up-down {
  0% {
    transform: translateY(15%);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(15%);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate(-1px) scale(var(--ReCaptchaScale));
  }

  20%,
  80% {
    transform: translate(3px) scale(var(--ReCaptchaScale));
  }

  30%,
  50%,
  70% {
    transform: translate(-6px) scale(var(--ReCaptchaScale));
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) scale(var(--ReCaptchaScale));
  }
}
