@media screen and (max-width: 1600px) {
  .about-passion-container {
    grid-template-areas:
      "Crypto Coding"
      "Design Gaming" !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 1500px) {
  :root {
    font-size: 12px !important;
  }
  .container-small {
    max-width: 1100px !important;
  }
  .contact-content {
    height: 60rem !important;
  }
}

@media screen and (max-width: 1300px) {
  :root {
    --ReCaptchaScale: 0.8 !important;
    font-size: 10px !important;
  }
  .container-small {
    max-width: 900px !important;
  }
  .contact-captcha {
    transform: scale(var(--ReCaptchaScale)) !important;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 8px !important;
  }
  .container-small {
    max-width: 800px !important;
  }
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 8px !important;
  }
  .home {
    flex-direction: column-reverse !important;
    align-items: stretch !important;
    justify-content: space-evenly !important;
  }
  .home-SVG {
    margin: 0 auto !important;
  }
  .home-SVG > * {
    margin: 0 !important;
    width: 50rem !important;
  }
  .menu-container > .menu-nav-elements {
    display: none !important;
  }
  .menu-hamburger {
    display: unset !important;
  }
  .watermark-text {
    font-size: 14.75rem !important;
  }
  .watermark {
    position: absolute !important;
    z-index: -1 !important;
    left: 50% !important;
    transform: translate(-50%) !important;
  }

  .home-watermark {
    top: 75%;
    transform: translate(-50%, -50%) translate(5rem, -20rem) !important;
  }

  .passion-watermark {
    margin-top: -9rem !important;
    transform: translate(-50%) !important;
  }

  .cta-watermark {
    margin-top: -6rem !important;
    color: var(--White5) !important;
    z-index: 0 !important;
    white-space: nowrap !important;
    user-select: none !important;
  }
  .about-passion-container {
    margin: 0 auto;
    grid-template-areas:
      "Crypto" "Coding"
      "Design" "Gaming" !important;
    grid-template-columns: 1fr !important;
    max-width: 400px !important;
  }
  .footer-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 7rem !important;
  }
  .passion-svg {
    transform: scale(1.4) !important;
    margin: 4rem !important;
  }
  .project-row,
  .project-row:nth-child(2n + 1) {
    flex-direction: column !important;
    gap: 5rem !important;
  }
  .width50 {
    width: 100% !important;
  }
  .grid-image-container {
    transform: rotate(-30deg) scale(0.8) !important;
  }
  .menu-nav-elements > .menu-title {
    font-size: 3.5rem !important;
  }
  .menu-nav-elements > .menu-svg {
    width: 12rem !important;
    transform: translateY(3rem) !important;
  }
  .menu-nav-elements:hover > .menu-svg {
    transform: translateY(2.4rem) !important;
  }
}

@media screen and (max-width: 700px) {
  .experiments-container > :nth-child(2) {
    margin-left: auto !important;
    margin-top: -5rem !important;
  }
  .watermark-text {
    font-size: 12.75rem !important;
  }
  .cta-watermark {
    margin-top: -2rem !important;
  }
  .project-description {
    flex-direction: column !important;
    gap: 5rem !important;
  }
}

@media screen and (max-width: 600px) {
  :root {
    font-size: 9px !important;
  }
  .home-SVG > * {
    width: 100% !important;
  }
  .home-subtitle {
    font-size: 2rem !important;
  }
  .home-title {
    font-size: 7.5rem !important;
  }
  .section-title {
    font-size: 3.5rem !important;
  }
  .container-large {
    padding: 0 5rem !important;
  }
  .container-small {
    padding: 0 5rem !important;
  }
  .about-passion-container {
    max-width: none !important;
  }
  .sub-section-title {
    font-size: 3rem !important;
  }
  .passion-title {
    font-size: 2.5rem !important;
  }
  .cta-section-title {
    font-size: 3rem !important;
  }
  .card-box {
    width: 100% !important;
    height: 50rem !important;
    flex-direction: column-reverse !important;
  }
  .card-container {
    flex-direction: column !important;
    gap: 1rem !important;
  }
  .card-info {
    height: 40% !important;
    width: 100% !important;
  }
  .card-img {
    height: 60% !important;
    width: 100% !important;
  }
  .card-date {
    transform: translate(0) !important;
    margin: 0 !important;
  }
  .card-container:nth-child(2n) > .card-date {
    transform: translate(0) !important;
    margin: 0 !important;
  }
  .card-container:nth-child(2n) {
    flex-direction: column !important;
  }
  .card-container:nth-child(2n) > .card-box {
    flex-direction: column-reverse !important;
  }
  .buttons-text-small {
    font-size: 1.5rem !important;
  }
  .experiments-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .experiments-container > :nth-child(2),
  .experiments-container > :nth-child(3) {
    margin: 0 !important;
    right: unset !important;
  }
  .about-first-section-container {
    flex-direction: column !important;
    align-items: center !important;
  }
  .about-text {
    width: 100% !important;
  }
  .about-SVG {
    margin: 0 !important;
    width: 40rem !important;
  }
  .cta-section-container {
    padding-bottom: 4rem !important;
  }
  .watermark-text {
    font-size: 8rem !important;
  }
  .project-watermark,
  .experiments-watermark,
  .about-watermark {
    margin-top: -2rem !important;
  }
  .passion-watermark {
    margin-top: -5rem !important;
  }
  .section-title-svg {
    width: 14rem !important;
    height: 2.5rem !important;
    transform: translateY(2.5rem) !important;
  }
  .grid-image-container {
    transform: rotate(-30deg) scale(0.6) !important;
  }
  .project-presentation-images {
    height: 60vh !important;
  }
  .project-title {
    font-size: 4rem !important;
  }
  .project-first-image {
    padding: 5rem 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
  }
  .cta-section-buttons {
    width: 100% !important;
    justify-content: space-around !important;
    gap: unset !important;
  }
  .grid-image-container {
    transform: rotate(-30deg) scale(0.45) !important;
  }
  .project-presentation-images {
    height: 30vh !important;
  }
}

@media screen and (max-width: 450px) {
  :root {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 400px) {
  :root {
    font-size: 7px !important;
  }
}

@media screen and (max-width: 350px) {
  :root {
    --ReCaptchaScale: 0.6 !important;
    font-size: 6px !important;
  }
  .contact-captcha {
    transform: scale(var(--ReCaptchaScale)) !important;
  }
}
@media screen and (max-width: 300px) {
  :root {
    --ReCaptchaScale: 0.5 !important;
    font-size: 5px !important;
  }
  .contact-captcha {
    transform: scale(var(--ReCaptchaScale)) !important;
  }
  .contact-content {
    height: 65rem !important;
  }
}

@media not screen and (hover: hover) and (pointer: fine) {
  .bubble-title {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  .bubble-git {
    opacity: 1 !important;
    transform: rotate(0) !important;
    transition: all 300ms ease-out !important;
    transition-delay: 175ms !important;
  }

  .bubble-container {
    background-color: var(--Black60) !important;
    transition: background 100ms !important;
  }
  .menu-hamburger-contact > .cta-container-blue {
    margin-bottom: 10rem !important;
  }
}
