@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  50% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  100% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  50% {
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%);
  }
  100% {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate(-1px) scale(var(--ReCaptchaScale));
            transform: translate(-1px) scale(var(--ReCaptchaScale));
  }

  20%,
  80% {
    -webkit-transform: translate(3px) scale(var(--ReCaptchaScale));
            transform: translate(3px) scale(var(--ReCaptchaScale));
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate(-6px) scale(var(--ReCaptchaScale));
            transform: translate(-6px) scale(var(--ReCaptchaScale));
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0) scale(var(--ReCaptchaScale));
            transform: translate3d(4px, 0, 0) scale(var(--ReCaptchaScale));
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate(-1px) scale(var(--ReCaptchaScale));
            transform: translate(-1px) scale(var(--ReCaptchaScale));
  }

  20%,
  80% {
    -webkit-transform: translate(3px) scale(var(--ReCaptchaScale));
            transform: translate(3px) scale(var(--ReCaptchaScale));
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate(-6px) scale(var(--ReCaptchaScale));
            transform: translate(-6px) scale(var(--ReCaptchaScale));
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0) scale(var(--ReCaptchaScale));
            transform: translate3d(4px, 0, 0) scale(var(--ReCaptchaScale));
  }
}

.menu-title {
  font-size: 1.875rem;
  font-weight: 500;
}

.home-subtitle {
  font-size: 2.5rem;
  color: var(--Black60);
  font-weight: 300;
}

.home-title {
  font-size: 8.75rem;
  line-height: 11.25rem;
  font-weight: 700;
  letter-spacing: 0.025em;
}

.buttons-text {
  font-size: 1.875rem;
  font-weight: 600;
  color: inherit;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.watermark-text {
  font-size: 18.75rem;
  font-weight: 700;
  color: var(--Black5);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.section-title {
  z-index: 1;
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.sub-section-title {
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.375rem;
}

.card-tags {
  color: var(--Black60);
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 700;
}

.buttons-text-small {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--Secondary);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.card-date-text {
  font-size: 1.875rem;
  font-weight: 200;
  text-align: center;
}

.paragraph {
  font-size: 1.625rem;
  color: var(--Black60);
  font-weight: 300;
  text-align: justify;
}

.passion-title {
  font-size: 3.125rem;
  font-weight: 600;
}

.cta-section-title {
  font-size: 3.75rem;
  font-weight: 600;
  color: var(--White);
  text-align: center;
  z-index: inherit;
}

.resume-button {
  font-size: 1.875rem;
  font-weight: 600;
  color: var(--White);
  text-decoration: underline;
}

.footer-title {
  color: var(--White);
  margin-bottom: 1.375rem;
  font-size: 2.5rem;
  font-weight: 500;
}

.footer-email {
  color: var(--White);
  font-size: 1.625rem;
}

.footer-copyright {
  margin-top: auto;
  color: var(--White60);
  font-size: 1rem;
  font-weight: 200;
}

.contact-text {
  font-size: 1.125rem;
  font-weight: 600;
}

.project-title {
  font-size: 5rem;
  font-weight: 700;
}

.paragraph-justify {
  font-size: 1.625rem;
  font-weight: 300;
  text-align: justify;
}

:root {
  --Backgound: #f2f2f2;
  --Black: #171717;
  --Black60: #17171799;
  --Black45: #17171773;
  --Black5: #1717170d;
  --BlackShadow: #1717174d;
  --White: #ffffff;
  --White60: #ffffff99;
  --White5: #ffffff1a;
  --Secondary: #0d6ede;
  --ImgBackgorund: #94d4f0;
  --Grey: #707070;
  --Grey45: #70707073;
  --Red: #e10052;

  --Scrollbar-size: 0.5rem;
  --Scrollbar-color: #0d6ede;

  --ReCaptchaScale: 1;

  font-size: 12px;

  scrollbar-color: #0d6ede #f2f2f2;

  scrollbar-color: var(--Scrollbar-color) var(--Backgound);
  scrollbar-width: thin;

  scroll-behavior: smooth;
}

/* Page*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: #171717;
  color: var(--Black);
}

body {
  height: 100%;
  background-color: #f2f2f2;
  background-color: var(--Backgound);
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 0.5rem;
  width: var(--Scrollbar-size);
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  background-color: var(--Backgound);
}

body::-webkit-scrollbar-thumb {
  background-color: #0d6ede;
  background-color: var(--Scrollbar-color);
  border-radius: 1rem;
}

.App {
  overflow-x: hidden;
}

section {
  position: relative;
}
/* Page End */

/* General */
.container-large {
  max-width: 1800px;
  width: 100%;
  padding: 0rem 10rem;
}

.container-small {
  max-width: 1300px;
  width: 100%;
  padding: 0rem 10rem;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.flex-columns {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.color-blue {
  color: #0d6ede;
  color: var(--Secondary);
}

.blur {
  -webkit-filter: blur(6px);
          filter: blur(6px);
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.text-center {
  text-align: center;
}
/* General End*/

@media screen and (max-width: 1600px) {
  .about-passion-container {
    grid-template-areas:
      "Crypto Coding"
      "Design Gaming" !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 1500px) {
  :root {
    font-size: 12px !important;
  }
  .container-small {
    max-width: 1100px !important;
  }
  .contact-content {
    height: 60rem !important;
  }
}

@media screen and (max-width: 1300px) {
  :root {
    --ReCaptchaScale: 0.8 !important;
    font-size: 10px !important;
  }
  .container-small {
    max-width: 900px !important;
  }
  .contact-captcha {
    -webkit-transform: scale(var(--ReCaptchaScale)) !important;
            transform: scale(var(--ReCaptchaScale)) !important;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 8px !important;
  }
  .container-small {
    max-width: 800px !important;
  }
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 8px !important;
  }
  .home {
    flex-direction: column-reverse !important;
    align-items: stretch !important;
    justify-content: space-evenly !important;
  }
  .home-SVG {
    margin: 0 auto !important;
  }
  .home-SVG > * {
    margin: 0 !important;
    width: 50rem !important;
  }
  .menu-container > .menu-nav-elements {
    display: none !important;
  }
  .menu-hamburger {
    display: unset !important;
  }
  .watermark-text {
    font-size: 14.75rem !important;
  }
  .watermark {
    position: absolute !important;
    z-index: -1 !important;
    left: 50% !important;
    -webkit-transform: translate(-50%) !important;
            transform: translate(-50%) !important;
  }

  .home-watermark {
    top: 75%;
    -webkit-transform: translate(-50%, -50%) translate(5rem, -20rem) !important;
            transform: translate(-50%, -50%) translate(5rem, -20rem) !important;
  }

  .passion-watermark {
    margin-top: -9rem !important;
    -webkit-transform: translate(-50%) !important;
            transform: translate(-50%) !important;
  }

  .cta-watermark {
    margin-top: -6rem !important;
    color: var(--White5) !important;
    z-index: 0 !important;
    white-space: nowrap !important;
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }
  .about-passion-container {
    margin: 0 auto;
    grid-template-areas:
      "Crypto" "Coding"
      "Design" "Gaming" !important;
    grid-template-columns: 1fr !important;
    max-width: 400px !important;
  }
  .footer-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }
  .passion-svg {
    -webkit-transform: scale(1.4) !important;
            transform: scale(1.4) !important;
    margin: 4rem !important;
  }
  .project-row,
  .project-row:nth-child(2n + 1) {
    flex-direction: column !important;
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
  .width50 {
    width: 100% !important;
  }
  .grid-image-container {
    -webkit-transform: rotate(-30deg) scale(0.8) !important;
            transform: rotate(-30deg) scale(0.8) !important;
  }
  .menu-nav-elements > .menu-title {
    font-size: 3.5rem !important;
  }
  .menu-nav-elements > .menu-svg {
    width: 12rem !important;
    -webkit-transform: translateY(3rem) !important;
            transform: translateY(3rem) !important;
  }
  .menu-nav-elements:hover > .menu-svg {
    -webkit-transform: translateY(2.4rem) !important;
            transform: translateY(2.4rem) !important;
  }
}

@media screen and (max-width: 700px) {
  .experiments-container > :nth-child(2) {
    margin-left: auto !important;
    margin-top: -5rem !important;
  }
  .watermark-text {
    font-size: 12.75rem !important;
  }
  .cta-watermark {
    margin-top: -2rem !important;
  }
  .project-description {
    flex-direction: column !important;
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
}

@media screen and (max-width: 600px) {
  :root {
    font-size: 9px !important;
  }
  .home-SVG > * {
    width: 100% !important;
  }
  .home-subtitle {
    font-size: 2rem !important;
  }
  .home-title {
    font-size: 7.5rem !important;
  }
  .section-title {
    font-size: 3.5rem !important;
  }
  .container-large {
    padding: 0 5rem !important;
  }
  .container-small {
    padding: 0 5rem !important;
  }
  .about-passion-container {
    max-width: none !important;
  }
  .sub-section-title {
    font-size: 3rem !important;
  }
  .passion-title {
    font-size: 2.5rem !important;
  }
  .cta-section-title {
    font-size: 3rem !important;
  }
  .card-box {
    width: 100% !important;
    height: 50rem !important;
    flex-direction: column-reverse !important;
  }
  .card-container {
    flex-direction: column !important;
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }
  .card-info {
    height: 40% !important;
    width: 100% !important;
  }
  .card-img {
    height: 60% !important;
    width: 100% !important;
  }
  .card-date {
    -webkit-transform: translate(0) !important;
            transform: translate(0) !important;
    margin: 0 !important;
  }
  .card-container:nth-child(2n) > .card-date {
    -webkit-transform: translate(0) !important;
            transform: translate(0) !important;
    margin: 0 !important;
  }
  .card-container:nth-child(2n) {
    flex-direction: column !important;
  }
  .card-container:nth-child(2n) > .card-box {
    flex-direction: column-reverse !important;
  }
  .buttons-text-small {
    font-size: 1.5rem !important;
  }
  .experiments-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .experiments-container > :nth-child(2),
  .experiments-container > :nth-child(3) {
    margin: 0 !important;
    right: unset !important;
  }
  .about-first-section-container {
    flex-direction: column !important;
    align-items: center !important;
  }
  .about-text {
    width: 100% !important;
  }
  .about-SVG {
    margin: 0 !important;
    width: 40rem !important;
  }
  .cta-section-container {
    padding-bottom: 4rem !important;
  }
  .watermark-text {
    font-size: 8rem !important;
  }
  .project-watermark,
  .experiments-watermark,
  .about-watermark {
    margin-top: -2rem !important;
  }
  .passion-watermark {
    margin-top: -5rem !important;
  }
  .section-title-svg {
    width: 14rem !important;
    height: 2.5rem !important;
    -webkit-transform: translateY(2.5rem) !important;
            transform: translateY(2.5rem) !important;
  }
  .grid-image-container {
    -webkit-transform: rotate(-30deg) scale(0.6) !important;
            transform: rotate(-30deg) scale(0.6) !important;
  }
  .project-presentation-images {
    height: 60vh !important;
  }
  .project-title {
    font-size: 4rem !important;
  }
  .project-first-image {
    padding: 5rem 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
  }
  .cta-section-buttons {
    width: 100% !important;
    justify-content: space-around !important;
    grid-gap: unset !important;
    gap: unset !important;
  }
  .grid-image-container {
    -webkit-transform: rotate(-30deg) scale(0.45) !important;
            transform: rotate(-30deg) scale(0.45) !important;
  }
  .project-presentation-images {
    height: 30vh !important;
  }
}

@media screen and (max-width: 450px) {
  :root {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 400px) {
  :root {
    font-size: 7px !important;
  }
}

@media screen and (max-width: 350px) {
  :root {
    --ReCaptchaScale: 0.6 !important;
    font-size: 6px !important;
  }
  .contact-captcha {
    -webkit-transform: scale(var(--ReCaptchaScale)) !important;
            transform: scale(var(--ReCaptchaScale)) !important;
  }
}
@media screen and (max-width: 300px) {
  :root {
    --ReCaptchaScale: 0.5 !important;
    font-size: 5px !important;
  }
  .contact-captcha {
    -webkit-transform: scale(var(--ReCaptchaScale)) !important;
            transform: scale(var(--ReCaptchaScale)) !important;
  }
  .contact-content {
    height: 65rem !important;
  }
}

@media not screen and (hover: hover) and (pointer: fine) {
  .bubble-title {
    opacity: 1 !important;
    -webkit-transform: translateY(0) !important;
            transform: translateY(0) !important;
  }

  .bubble-git {
    opacity: 1 !important;
    -webkit-transform: rotate(0) !important;
            transform: rotate(0) !important;
    transition: all 300ms ease-out !important;
    transition-delay: 175ms !important;
  }

  .bubble-container {
    background-color: var(--Black60) !important;
    transition: background 100ms !important;
  }
  .menu-hamburger-contact > .cta-container-blue {
    margin-bottom: 10rem !important;
  }
}

