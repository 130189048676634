:root {
  --Backgound: #f2f2f2;
  --Black: #171717;
  --Black60: #17171799;
  --Black45: #17171773;
  --Black5: #1717170d;
  --BlackShadow: #1717174d;
  --White: #ffffff;
  --White60: #ffffff99;
  --White5: #ffffff1a;
  --Secondary: #0d6ede;
  --ImgBackgorund: #94d4f0;
  --Grey: #707070;
  --Grey45: #70707073;
  --Red: #e10052;

  --Scrollbar-size: 0.5rem;
  --Scrollbar-color: #0d6ede;

  --ReCaptchaScale: 1;

  font-size: 12px;

  scrollbar-color: var(--Scrollbar-color) var(--Backgound);
  scrollbar-width: thin;

  scroll-behavior: smooth;
}

/* Page*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: var(--Black);
}

body {
  height: 100%;
  background-color: var(--Backgound);
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: var(--Scrollbar-size);
}

body::-webkit-scrollbar-track {
  background-color: var(--Backgound);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--Scrollbar-color);
  border-radius: 1rem;
}

.App {
  overflow-x: hidden;
}

section {
  position: relative;
}
/* Page End */

/* General */
.container-large {
  max-width: 1800px;
  width: 100%;
  padding: 0rem 10rem;
}

.container-small {
  max-width: 1300px;
  width: 100%;
  padding: 0rem 10rem;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.flex-columns {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.color-blue {
  color: var(--Secondary);
}

.blur {
  filter: blur(6px);
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.text-center {
  text-align: center;
}
/* General End*/
